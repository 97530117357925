.App {
    text-align: center;
    background-color: #1c1c1d;
    scroll-behavior: smooth;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #1c1c1d;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 3vmin);
    color: white;
    position: sticky;
    top: 0;
}

.App-screen-black {
    background-color: #1c1c1d;
    color: #ccb4d6;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 100vh;
    position: sticky;
    top: 0;
    font-size: calc(5px + 1.3vmin);
    box-shadow: 0px -25px 5px rgba(28, 28, 28, .1), 0px -20px 5px rgba(28, 28, 28, .35), 0px -15px 5px rgba(28, 28, 28, .65), 0px -10px 5px rgba(28, 28, 28, 1);
}

.App-screen-purple {
    background-color: #745494;
    color: #1c1c1d;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 100vh;
    position: sticky;
    top: 0;
    font-size: calc(5px + 1.3vmin);
    box-shadow: 0px -25px 5px rgba(116, 84, 148, .1), 0px -20px 5px rgba(116, 84, 148, .35), 0px -15px 5px rgba(116, 84, 148, .65), 0px -10px 5px rgba(116, 84, 148, 1);
}

.App-screen-light-purple {
    background-color: #bd95cf;
    color: #1c1c1d;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 100vh;
    position: sticky;
    top: 0;
    font-size: calc(5px + 1.3vmin);
    box-shadow: 0px -25px 5px rgba(189, 149, 207, .1), 0px -20px 5px rgba(189, 149, 207, .35), 0px -15px 5px rgba(189, 149, 207, .65), 0px -10px 5px rgba(189, 149, 207, 1);
}

.App-div-black {
    background-color: #1c1c1d;
    color: #ccb4d6;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.responsive {
    width: 100%;
    height: auto;
}

.important-text {
    color: #ec7287;
}

html {
    background-color: #1c1c1d;
}

.cta {
    display: flex;
    padding: 10px 45px;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #1c1c1d;
    background: #ccb4d6;
    transition: 1s;
    box-shadow: 6px 6px 0 #745494;
    transform: skewX(-15deg);
}

@media screen and (max-width: 600px) {
    .cta {
        display: flex;
        padding: 8px 30px;
        text-decoration: none;
        font-family: "Poppins", sans-serif;
        font-size: 17px;
        font-weight: bold;
        color: #1c1c1d;
        background: #ccb4d6;
        transition: 1s;
        box-shadow: 6px 6px 0 #745494;
    }
}

.cta:focus {
    outline: none;
}

.cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #ec7287;
    color: #ec7287 !important;
}

.cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
}

.cta:hover span:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px;
}

span {
    transform: skewX(15deg);
}

span:nth-child(2) {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
}

path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

.cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

@keyframes color_anim {
    0% {
        fill: #745494;
    }
    50% {
        fill: #ec7287;
    }
    100% {
        fill: #745494;
    }
}

.nav-neon-button {
    position: relative;
    background: #444;
    color: #ccb4d6;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
}

.nav-neon-button:hover {
    letter-spacing: 0.2rem;
    padding: 1.1rem 3.1rem;
    background: #ccb4d6;
    color: #ccb4d6;
    /* box-shadow: 0 0 35px #ccb4d6; */
    animation: box 3s infinite;
}

.nav-neon-button::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: #272822;
}

.nav-neon-button span {
    position: relative;
    z-index: 1;
}

.nav-neon-button i {
    position: absolute;
    inset: 0;
    display: block;
}

.nav-neon-button i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid #ccb4d6;
    background: #272822;
    transition: 0.2s;
}

.nav-neon-button:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
}

.nav-neon-button i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid #ccb4d6;
    background: #272822;
    transition: 0.2s;
}

.nav-neon-button:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
}

@keyframes move {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes box {
    0% {
        box-shadow: #27272c;
    }
    50% {
        box-shadow: 0 0 25px #ccb4d6;
    }
    100% {
        box-shadow: #27272c;
    }
}